/* global $, location, Swiper */

$(document).ready(function () {
  var mySwiper = new Swiper('.swiper-container', {
    effect: 'fade',
    speed: 200,
    loop: true,
    autoplay: 4000,
    paginationClickable: true,
    keyboardControl: true,
    pagination: '.swiper-pagination',
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev'
  })
  mySwiper.loop = true

  $('a[href*="#"]:not([href="#"])').click(function () {
    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
      var target = $(this.hash)
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000)
        return false
      }
    }
  })

  // $('.match-height').matchHeight(options)
})
